@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.9e4ff2ca.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logo:before {
  content: "\e900";
  color: #fff;
  font-size: 50px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #131313;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Work Sans', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: opacity 250ms;
  transition: opacity 250ms;
}
a:hover {
  opacity: 0.6;
}

p {
  font-size: 16px;
  margin: 0 0 0 -10px;
}

p span {
  margin-left: 2px;
}

